:root {
  --color-primary: #642a7a;
  --color-secondary: #adc508;
  --color-accent-01: #eb008b;
  --color-accent-02: #f26f21;
  --color-accent-03: #26a9e0;
  --color-accent-04: #2a378d;
}

#__next {
  @apply min-h-screen flex flex-col;
}

@tailwind base;

/* Write your own custom base styles here */
@layer base {
  html,
  body {
    @apply min-h-screen;
    scroll-behavior: smooth;
  }

  html {
    @apply font-body;
  }

  @page {
    size: A4;
  }

  body {
    background-color: #f6efe8;
    background-image: url(/assets/images/body-background.svg);
    background-position: 50% top;
    background-repeat: no-repeat;
  }

  @media screen(md) {
    body {
      background-position: 50% 96px;
    }
  }

  @media print {
    html {
      margin: 0;
    }

    body {
      margin: 0;
      background: none;
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  address {
    display: block;
    font-style: normal;
  }
}

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    }
    .text-shadow-md {
      text-shadow: 4px 4px 8px black;
    }
    .text-shadow-lg {
      text-shadow: 15px 15px 30px black;
    }
    .text-shadow-none {
      text-shadow: none;
    }
  }
}

@tailwind components;
@tailwind utilities;

/*
 * nProgress overrides, needs to be outside @layer
 * otherwise PurgeCSS will purge it.
 */
#nprogress .bar {
  background: var(--color-accent-04) !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--color-accent-04), 0 0 5px var(--color-accent-04) !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--color-accent-04) !important;
  border-left-color: var(--color-accent-04) !important;
}
