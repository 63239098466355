.root {
  @apply lg:text-lg xl:text-xl text-gray-800;

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure {
    &:not(:last-child) {
      @apply mb-6;
    }
  }

  h1,
  h2,
  h3 {
    @apply block font-medium mt-12;

    &:first-child {
      @apply mt-0;
    }
  }

  h1,
  h2 {
    @apply text-2xl md:text-4xl;

    &:not(:last-child) {
      @apply mb-2;
    }
  }

  h3 {
    @apply text-xl md:text-2xl mt-8;

    &:not(:last-child) {
      @apply mb-2;
    }
  }

  a {
    @apply text-powerblue hover:underline;
  }

  li {
    @apply ml-5 list-disc;
  }

  iframe {
    @apply block;
  }

  figure {
    @apply -mx-4;
  }

  figcaption {
    @apply text-sm text-gray-500 mt-2 px-4;
  }
}
